<script setup lang="ts">

const {imageUrl, name, size, textClass} = defineProps(['imageUrl', 'name', 'size', 'textClass']);

const hasImageUrl:boolean = imageUrl!=null && imageUrl.toString().length>0;
const itemSize = size??12;

const getNameInitials = (name:any)=>{
    let initials = '';
    try{
        let stringedName = name??'Aphelia Admin';
        let brokenNames = stringedName.split(' ');
        brokenNames = brokenNames.filter((item:any)=>item!='');
        const firstName = brokenNames[0]?.trim()??'A';
        const lastName = brokenNames[1]?.trim()??'A';
        if(brokenNames.length>1){
            initials = firstName.at(0)!+lastName.at(0)!;
        }else{
            initials = firstName.at(0)!+firstName.at(0)!;
        }
    }catch (e) {
        initials = 'AA';
    }
    return initials.toUpperCase();
}

</script>

<template>
    <div :key="hasImageUrl"  :class="(`w-${itemSize} h-${itemSize}`)" class="bg-gray-300 rounded-full items-center justify-center flex outline outline-1 outline-brand-color">
        <img v-if="hasImageUrl" :class="`w-${itemSize} h-${itemSize}`" class=" flex-none rounded-full bg-gray-50 object-cover"
             :src="imageUrl" alt="" referrerpolicy="no-referrer"/>
        <span v-else class="align-middle text-gray-600 font-medium" :class="[textClass]">{{ getNameInitials(name) }}</span>
    </div>
</template>

<style scoped>

</style>